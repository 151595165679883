<template>
  <div class="parallax">
      <slot></slot>
  </div>
</template>

<script>
export default {
    name: "Parallax"
}
</script>

<style scoped>
.parallax {
  /* The image used */
  background-image: url("../assets/images/parallax1.jpg");

  /* Set a specific height */
  height: 400px; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>