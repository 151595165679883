const observer = new IntersectionObserver(
  (enteries) => {
    const nav = document.getElementById("nav");
    const bgColor = "bg-white";
    const altColor = "bg-white";
    const shadow = "shadow-md";
    const noShadow = "shadow-none";

    enteries.forEach((entry) => {
      if (entry.isIntersecting) {
        nav.classList.contains(bgColor)
          ? nav.classList.replace(bgColor, altColor)
          : nav.classList.add(altColor);

        nav.classList.contains(shadow)
          ? nav.classList.replace(shadow, noShadow)
          : nav.classList.add(noShadow);
      } else {
        nav.classList.contains(altColor)
          ? nav.classList.replace(altColor, bgColor)
          : nav.classList.add(bgColor);

        nav.classList.contains(noShadow)
          ? nav.classList.replace(noShadow, shadow)
          : nav.classList.add(shadow);
      }
    });
  },
  { root: null, threshold: 1 }
);

export default {
  mounted(el) {
    observer.observe(el);
  },
};
