<template>
  <div
    class="flex justify-between items-center py-3 px-10 md:px-36 lg:px-64 fixed w-full z-50 transition-all bg-white"
    id="nav"
  >
    <span class="font-bold text-green-800 text-3xl hide-on-sm">RISE - <small>Rights Institute for Social Empowerment</small></span>
    <div>
      <ul class="flex flex-row items-center hide-on-sm">
        <li
          v-for="(item, i) in items"
          :key="i"
          class="capitalize text-gray-700 px-2 ml-2 rounded hover:text-black transition-colors"
        >
          <router-link :to="`${item.link}`">{{ item.name }}</router-link>
        </li>
        <li
          class="capitalize text-gray-700 px-2 ml-2 rounded hover:text-black transition-colors"
        >
          <a href="https://www.risemw.org/gbv">Gender Based Violence (GBV) Approaches</a>
        </li>
      </ul>

      <!-- <div
        class="w-8 h-5 flex flex-col justify-between hide-on-md btn"
        v-clicked="changeDrawerState"
      >
        <div class="border"></div>
        <div class="border"></div>
        <div class="border"></div>
      </div> -->
      <i class="fas fa-bars text-gray-700 hide-on-md" @click="changeDrawerState"></i>
    </div>
  </div>

  <!-- Mobile drawer -->
  <div
    :class="
      `fixed top-0 left-0 w-full h-screen z-50 slide-animation ${
        drawerState ? 'slide-in' : 'slide-out'
      }`
    "
  >
    <div
      class="relative w-5/6 h-full bg-white shadow-2xl pb-3"
    >
      <div class="text-left px-3 py-3 text-gray-600" @click="changeDrawerState">
        <i class="fas fa-chevron-left mr-2"></i> Close
      </div>
      <hr>
      <ul class="flex flex-col">
        <li
          v-for="(item, i) in items"
          :key="i"
          class="capitalize text-gray-700 px-2 my-1 rounded hover:text-black transition-colors"
        >
          <router-link
            :to="`${item.link}`"
            v-clicked="changeDrawerState"
            class="block p-2"
            >{{ item.name }}</router-link
          >
        </li>
        <li
          class="capitalize text-gray-700 px-2 ml-2 rounded hover:text-black transition-colors"
        >
          <a @click="changeDrawerState" href="https://www.risemw.org/gbv">GBV</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  data() {
    return {
      items: [
        { name: "home", link: "/" },
        { name: "about", link: "/about" },
        { name: "programmes", link: "/programs" },
        { name: "know your rights", link: "/learn" },
        // { name: "donate", link: "/donate" },
        // { name: "contact us", link: "/contacts" },
      ],
      drawerOpen: false,
    };
  },
  methods: {
    changeDrawerState() {
      this.drawerOpen = !this.drawerOpen;
    },
  },
  computed: {
    drawerState() {
      return this.drawerOpen;
    },
  },
};
</script>

<style scoped>
#nav a.router-link-exact-active {
  color: #2c7247;
  font-weight: 600;
}

.slide-animation {
  transition: 0.3s all;
}

.slide-in {
  left: 0;
}

.slide-out {
  left: -100%;
}

.hide-on-sm {
  visibility: visible;
  font-size: inherit;
}

.hide-on-md {
  visibility: hidden;
  width: 0 !important;
  height: 0 !important;
}

@media (max-width: 768px) {
  .hide-on-sm {
    visibility: hidden;
    width: 0 !important;
    font-size: 0;
  }

  .hide-on-md {
    visibility: visible;
  }

  .hide-on-md.btn {
    width: 2rem !important;
    height: 1.25rem !important;
  }

  .hide-on-md.cover {
    width: 100% !important;
  }
}

@media (max-width: 430px) {
}
</style>
