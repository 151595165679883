<template>
  <div class="w-full min-h-screen">
      <slot></slot>
  </div>
</template>

<script>
export default {
    name: "Section"
}
</script>

<style>

</style>