const intersectionbserver = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add("on-enter");
      // scrollobserver.unobserve(entry.target);
    } else {
      entry.target.classList.remove("on-enter");
    }
  });
}, {threshold: .25});

export default {
  mounted(el) {
    el.classList.add("before-enter");
    intersectionbserver.observe(el);
  },
};
