const observer = new IntersectionObserver(
  (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const num = parseInt(entry.target.textContent);

        for (let i = 0; i <= num; i++) {
          setTimeout(() => {
            entry.target.textContent = i.toString();
          }, i * 5)
        }

        observer.unobserve(entry.target);
      }
    });
  },
  { root: null, threshold: 1 }
);

export default {
  mounted(el) {
    observer.observe(el);
  },
};
