<template>
  <!-- landing screen -->
  <Section class="relative h-screen bg-white" id="home" v-smartnav>
    <div class="w-full h-full bg-black bg-opacity-30">
      <div
        class="
          w-full
          h-full
          flex
          justify-end
          items-center
          px-5
          md:px-36
          lg:px-64
          pt-24
        "
      >
        <h2 class="text-right text-white text-4xl md:text-5xl">
          Building
          <span class="md:block text-white font-black"
            >A Human Rights Based <span class="">Culture</span></span
          >
          Is Our <span class="font-bold">Core Bussiness.</span>
          <p class="text-base text-gray-100 font-medium mt-5 md:mt-3">
            <span
              class="bg-white text-gray-700 px-3 py-2 rounded-full shadow-xl"
              >Know more about your rights
              <router-link
                to="/learn"
                class="text-green-700 hover:text-green-900"
                >here</router-link
              >.</span
            >
          </p>
        </h2>
      </div>
    </div>
  </Section>

  <!-- about us -->
  <Section
    class="
      flex flex-col
      md:flex-row
      justify-start
      items-center
      px-5
      md:px-36
      lg:px-64
    "
  >
    <div class="w-full md:w-1/2 h-full relative">
      <br />
      <div class="w-9/12 h-72 absolute bottom-1/3 left-1/4 z-0">
        <img
          src="../assets/images/ecd.jpeg"
          alt=""
          class="h-full object-cover float-right"
        />
      </div>
      <div
        class="
          w-10/12
          md:w-full
          h-28
          shadow-xl
          border-8 border-white
          absolute
          top-1/2
          -left-4
          md:left-16
          z-0
        "
      ></div>
      <div class="w-9/12 h-72 shadow-xl bg-green-800 z-30"></div>

      <br />
      <router-link
        to="/about"
        class="
          capitalize
          text-gray-700
          border
          hover:border-gray-800 hover:text-gray-800
          rounded-full
          transition-all
          py-1
          px-2
        "
        >read more <i class="fi fi-rr-caret-right relative top-1"></i
      ></router-link>
    </div>

    <div class="w-full md:w-1/2 md:ml-20 mt-5 md:mt-0 text-left">
      <h4 class="font-bold text-3xl capitalize text-green-800 leading-tight">
        About Us
      </h4>
      <br />

      <p class="text-gray-800 text-justify">
        Rights Institute for Social Empowerment (RISE) is a women’s rights
        organization with a deliberate mandate to promote women and girls’
        rights. Rights Institute for Social Empowerment (RISE) is a Women Rights
        Organization (WRO) dedicated to the advancement of equality, promotion
        of social justice and the respect of human dignity for women, girls
        including LBTI women and those living with HIV and disabilities.
      </p>

      <br />
      <p class="text-gray-800 text-justify">
        Advocacy and direct empowerment of women, girls, young people, children
        and their communities by building their capabilities so that they are
        able to rise up to the consciousness and capacity of advocating for the
        promotion of their human rights, protect themselves and be protected
        from human rights of all forms and be able to actively participate in
        the governance, policy formulation and decision making processes
        including democratization in Malawi.
      </p>
    </div>
  </Section>

  <!-- indicators -->
  <!-- <div
    id="stats"
    class="px-10 md:px-36 lg:px-64 py-2 flex flex-col md:flex-row justify-between items-center bg-white"
  >
    <Indicator
      caption="Children With Access to Quality ECD Services"
      amount="7800"
      icon="users"
      class="my-10"
    />
    <Indicator caption="Care Givers Trained" amount="4000" icon="doctor" />
    <Indicator
      caption="Communities changed"
      amount="1000"
      icon="location-alt"
    />
  </div> -->

  <!-- Parallax view -->
  <Parallax class="mt-6 md:mt-0">
    <div
      class="
        w-full
        h-full
        bg-black bg-opacity-25
        py-10
        px-10
        md:px-36
        lg:px-64
        flex flex-col
        md:flex-row
        justify-center
        md:justify-between
        items-center
      "
    >
      <h3
        class="
          text-5xl
          md:text-7xl
          capitalize
          text-white
          font-semibold
          text-center
          md:text-left
        "
        v-scrollobserver
      >
        be <br />
        a tool for <br />
        <span class="text-green-600 font-bold">change</span>.
      </h3>

      <div
        class="
          flex
          justify-center
          md:justify-end
          items-center
          w-full
          md:w-1/4
          mt-4
          md:mt-0
        "
      >
        <OutlinedButton text="donate" href="/donate" class="rounded-full" />
      </div>
    </div>
  </Parallax>

  <!-- Success stories -->
  <Section
    class="
      flex flex-col
      justify-center
      md:flex-row md:justify-between
      items-center
      px-10
      md:px-36
      lg:px-64
      py-14
    "
    id="latest"
  >
    <div class="w-full md:w-1/2 h-full relative">
      <br />
      <div class="w-9/12 h-72 absolute bottom-1/3 left-1/4 z-0">
        <img
          src="../assets/images/hands.jpg"
          alt=""
          class="h-full w-full object-cover"
        />
      </div>
      <div
        class="
          w-10/12
          md:w-full
          h-10
          md:h-28
          shadow-xl
          border-8 border-white
          absolute
          top-1/2
          left-16
          z-0
        "
      ></div>
      <div class="w-9/12 h-72 shadow-xl bg-green-800 z-30"></div>

      <br />
      <router-link
        to="/programs"
        class="
          capitalize
          text-gray-700
          border
          hover:border-gray-800 hover:text-gray-800
          rounded-full
          transition-all
          py-1
          md:px-2
        "
        >read more <i class="fi fi-rr-caret-right relative top-1"></i
      ></router-link>
    </div>

    <div class="w-full md:w-1/2 md:ml-20 mt-4 md:mt-0 text-left">
      <h4 class="font-bold text-3xl capitalize text-green-800 leading-tight">
        Introducing MAMA
      </h4>
      <br />

      <p class="text-gray-800 text-justify">
        Malawi Advocacy mentoring alliance <b>(MAMA)</b> exists to build
        stronger and more inclusive grassroots SRHR movements in Malawi. MAMA is
        comprised of 33 Malawian based small CSOs organizations that are
        committed to improve the sexual and reproductive health and rights of
        everyone, especially young people and girls in Malawi. Some of these
        partner organizations are Human Rights Defenders Coalition(HRDC), Youth
        Network for SRHR, Network of youths with HIV and Network of girls for
        SRH and other Community Based Organizations(CBO) jointly come together
        to share expertise, skills and capacity in the network.
      </p>
    </div>
  </Section>

  <!-- Gallery -->
  <!-- <Section class="px-10 md:px-36 py-5 flex-col justify-start items-center">
    <p class="text-center text-4xl font-semibold text-gray-800">The Gallery</p>

    <br />
    <div
      class="flex flex-col justify-center md:flex-row md:justify-between w-full mb-2"
      v-scrollobserver
    >
      <GalleryItem>
        <img src="@/assets/images/child.jpg" alt="" />
      </GalleryItem>
      <GalleryItem class="md:mx-2">
        <img src="@/assets/images/parallax1.jpg" alt="" />
      </GalleryItem>
      <GalleryItem>
        <img src="@/assets/images/parallax2.jpg" alt="" />
      </GalleryItem>
    </div>

    <div
      class="flex flex-col justify-center md:flex-row md:justify-between w-full mb-2"
      v-scrollobserver
    >
      <GalleryItem>
        <img src="@/assets/images/child.jpg" alt="" />
      </GalleryItem>
      <GalleryItem class="md:mx-2">
        <img src="@/assets/images/parallax1.jpg" alt="" />
      </GalleryItem>
      <GalleryItem>
        <img src="@/assets/images/parallax2.jpg" alt="" />
      </GalleryItem>
    </div>
  </Section> -->
</template>

<script>
// @ is an alias to /src
import Section from "@/components/Section";
// import Indicator from "@/components/Indicator";
import Parallax from "@/components/Parallax";
// import GalleryItem from "@/components/GalleryItem";
import OutlinedButton from "@/components/OutlinedButton";

export default {
  name: "Home",
  data() {
    return {
      images: ["child.jpg", "parallax1.jpg", "parallax2.jpg"],
    };
  },
  components: {
    Section,
    Parallax,
    // GalleryItem,
    OutlinedButton,
  },
};
</script>

<style scoped>
#home {
  background-image: url("../assets/images/DSCN3887.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position-y: -120px; */
}

.before-enter {
  opacity: 0;
  transition: 0.3s opacity ease-in;
}

.on-enter {
  opacity: 1;
}

.landing-parallax {
  /* The image used */
  background-image: url("../assets/images/child.jpg");

  /* Set a specific height */
  height: 100vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.text-shadow {
  text-shadow: 1px 0px 15px rgba(0, 0, 0, 0.4);
}
</style>
