import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ScrollObserver from "./directives/scrollobserver";
import SmartNav from "./directives/SmartNav";
import Counter from "./directives/Counter";
import Clicked from "./directives/Clicked";
import "./assets/tailwind.css";
import "./assets/flaticons/css/uicons-regular-rounded.css";
import "./assets/fontawesome/css/all.min.css";
import "./assets/fontawesome/css/brands.min.css";

createApp(App)
  .directive("scrollobserver", ScrollObserver)
  .directive("smartnav", SmartNav)
  .directive("counter", Counter)
  .directive("clicked", Clicked)
  .use(store)
  .use(router)
  .mount("#app");
