<template>
  <router-link
    :to="`${href}`"
    class="capitalize text-white border border-white py-1 px-3 hover:bg-green-700 hover:border-green-700 hover:shadow-md transition-colors"
  >
    {{ text }}
  </router-link>
</template>

<script>
export default {
  name: "OutlinedButton",
  props: {
    text: String,
    href: String,
  },
};
</script>

<style></style>
