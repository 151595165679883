<template>
  <!-- Connect -->
  <div class="px-10 md:px-36 lg:px-64 py-6 bg-white">
    <p class="text-3xl text-gray-700 text-center font-light">Connect With Us</p>
    <br />
    <div class="flex justify-evenly items-center text-3xl text-gray-600">
      <a href="" class="hover:text-green-600 transition-colors"
        ><i class="fab fa-facebook"></i
      ></a>
      <a href="" class="hover:text-green-600 transition-colors"
        ><i class="fab fa-linkedin"></i
      ></a>
      <a href="" class="hover:text-green-600 transition-colors"
        ><i class="fab fa-google"></i
      ></a>
    </div>
  </div>

  <!-- footer -->
  <div class="px-10 md:px-36 lg:px-64 py-10 text-center md:text-left" id="footer">
    <div
      class="flex flex-col justify-center md:flex-row md:justify-between items-center"
    >
      <div>
        <p class="text-xl">Newsletter</p>

        <br />
        <p class="text-gray-700">
          Subscribe to our newsletter to recieve our news and updates in your
          inbox.
        </p>

        <div class="mt-3">
          <form @submit.prevent="" class="flex">
            <input
              type="email"
              placeholder="Email"
              class="flex-1 bg-white p-2 focus:outline-none"
            />
            <button
              type="submit"
              class="bg-green-600 hover:bg-green-800 transition-colors text-white px-3 capitalize"
            >
              subscribe
            </button>
          </form>
        </div>
      </div>

      <div class="mt-5 md:mt-0">
        <p class="text-xl">Navigation</p>

        <br />
        <ul class="text-gray-700">
          <li><a href="#top">Top</a></li>
          <li><a href="/about">About</a></li>
          <li><a href="/programs">Programs</a></li>
        </ul>
      </div>

      <!-- <div class="mt-5 md:mt-0">
          <p class="text-xl">Others</p>

          <br>
          <ul class="text-gray-700">
              <li><router-link to="/about">About us</router-link></li>
              <li><router-link to="/donate">Donate</router-link></li>
              <li><router-link to="/programs">Programs</router-link></li>
          </ul>
      </div> -->

      <div class="mt-5 md:mt-0">
        <p class="text-xl">Contact Us</p>

        <br />
        <ul class="text-gray-700">
          <li
            class="flex flex-col justify-center items-center md:flex-row md:justify-start"
          >
            <i class="fi fi-rr-marker md:mr-1"></i> P.O. Box 31381, Lilongwe 3, Malawi
          </li>
          <!-- <li
            class="flex flex-col justify-center items-center md:flex-row md:justify-start"
          >
            <i class="fi fi-rr-smartphone md:mr-1"></i> +265 (0) 996 523 009
          </li> -->
          <li
            class="flex flex-col justify-center items-center md:flex-row md:justify-start"
          >
            <i class="fi fi-rr-envelope md:mr-2"></i> info@risemalawi.org
          </li>
        </ul>
      </div>
    </div>

    <br />
    <hr />

    <br />
    <div class="flex flex-col md:flex-row justify-between">
      <small class="text-gray-600"
        >&copy; Rights Institute for Social Empowerment - RISE |
        {{ year }}</small
      >
      <!-- <small class="text-gray-600">www.website.org</small> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    year() {
      return Date().split(" ")[3];
    },
  },
};
</script>

<style></style>
